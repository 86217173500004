@charset "UTF-8";
.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #7D8B99;
}

.token.punctuation {
  color: #5F6364;
}
.token.important {
  font-weight: normal;
}
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.function-name,
.token.constant,
.token.symbol,
.token.deleted {
  color: #c92c2c;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.function,
.token.builtin,
.token.inserted {
  color: #2f9c0a;
}

.token.operator {
  color: #ff2211;
}

.token.entity,
.token.url,
.token.variable {
  color: #a67f59;
}

.token.atrule,
.token.attr-value,
.token.keyword,
.token.class-name {
  color: #1990b8;
}

.token.regex,
.token.important {
  color: #e90;
}

.language-css .token.string,
.style .token.string {
  color: #a67f59;
  background: rgba(255, 255, 255, 0.5);
}

.namespace {
  opacity: 0.7;
}

.token.tab:not(:empty):before,
.token.cr:before,
.token.lf:before {
  color: #e0d7d1;
}

.q-markdown {
  position: relative;
}
.q-markdown details > summary {
  display: list-item;
}
.q-markdown code, .q-markdown pre {
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
}
.q-markdown pre {
  border-radius: 4px;
  padding: 5px;
  margin: 0;
  background-size: 1.5em 1.5em;
  background-origin: content-box;
  background-attachment: local;
  max-height: inherit;
  height: inherit;
  display: block;
  overflow: auto;
  position: relative;
  font-size: 12px;
  background: #eceff1;
  color: #212121;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5em;
  tab-size: 4;
  hyphens: none;
}
.q-markdown pre code {
  border-radius: 0;
  width: max-content;
}
.q-markdown--heading-h1 {
  font-size: 2rem;
  line-height: 2rem;
  padding: 1rem 0;
  font-weight: 500;
  margin: 0 0 1rem;
}
.q-markdown--heading-h2 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0.5rem 0;
  font-weight: 500;
  margin: 1rem 0 1rem;
}
.q-markdown--heading-h3 {
  font-size: 1.1rem;
  line-height: 1.1rem;
  padding: 0.45rem 0;
  margin: 1rem 0 1rem;
}
.q-markdown--heading-h4 {
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.25rem 0;
  margin: 1rem 0;
}
.q-markdown--heading-h5 {
  font-size: 0.9rem;
  margin: 1rem 0;
}
.q-markdown--heading-h6 {
  font-size: 0.8rem;
  margin: 1rem 0;
}
.q-markdown--heading .q-markdown--link {
  border-bottom: inherit;
  color: inherit;
}
.q-markdown--heading--anchor-link {
  cursor: pointer;
}
.q-markdown--heading--anchor-link:after {
  content: " #";
  opacity: 0;
  transition: opacity 0.2s;
}
.q-markdown--heading--anchor-link:hover:after {
  opacity: 1;
}
.q-markdown--title-heavy {
  border-bottom: 3px solid #ccc;
}
.q-markdown--title-light {
  border-bottom: 1px solid #ccc;
}
.q-markdown--image {
  max-width: 100%;
  height: auto;
}
.q-markdown--link {
  font-weight: 500;
  text-decoration: none;
  outline: 0;
  border-bottom: 1px dotted currentColor;
  text-align: center;
  transition: opacity 0.2s;
}
.q-markdown--link-local {
  font-family: inherit;
}
.q-markdown--link-external {
  font-family: inherit;
}
.q-markdown--link-external:after {
  content: "";
  font-family: Material Icons;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  margin: 0 0 0 3px;
  padding: 0;
}
.q-markdown--token {
  white-space: nowrap;
  background: #fafafa;
  color: #212121;
  border: #616161 solid 1px;
  padding: 1px 2px;
  font-family: inherit;
  border-radius: 4px;
}
.q-markdown--note {
  margin: 14px 0;
  padding: 10px;
  font-size: 1em;
  letter-spacing: 0.5px;
  background: #eceff1;
  color: #212121;
  font-weight: 400;
}
.q-markdown--note > p:last-child, .q-markdown--note .q-markdown--note:last-child {
  margin-bottom: 0;
}
.q-markdown--note-- {
  border-left: 10px solid #aeaeae;
  border-radius: 8px 0 0 8px;
}
.q-markdown--note-- .q-markdown--link {
  color: #7b7b7b;
}
.q-markdown--note--info {
  border-left: 10px solid #92c2f2;
  border-radius: 8px 0 0 8px;
  color: #424242;
  background: #bbdefb;
}
.q-markdown--note--info .q-markdown--link {
  color: #378fe7;
}
.q-markdown--note--info .q-markdown--note-title {
  color: #378fe7;
}
.q-markdown--note--tip {
  border-left: 10px solid #8dd290;
  border-radius: 8px 0 0 8px;
  color: #424242;
  background: #c8e6c9;
}
.q-markdown--note--tip .q-markdown--link {
  color: #46b34b;
}
.q-markdown--note--tip .q-markdown--note-title {
  color: #46b34b;
}
.q-markdown--note--warning {
  border-left: 10px solid #ffad80;
  border-radius: 8px 0 0 8px;
  color: #424242;
  background: #ffe0b2;
}
.q-markdown--note--warning .q-markdown--link {
  color: #ff6b1a;
}
.q-markdown--note--warning .q-markdown--note-title {
  color: #ff6b1a;
}
.q-markdown--note--danger {
  border-left: 10px solid #ff5b6d;
  border-radius: 8px 0 0 8px;
  color: #424242;
  background: #ffcdd2;
}
.q-markdown--note--danger .q-markdown--link {
  color: #dc5959;
}
.q-markdown--note--danger .q-markdown--note-title {
  color: #dc5959;
}
.q-markdown--note-title {
  font-weight: 800;
  margin-left: -4px;
  margin-right: -4px;
  padding: 0 4px;
  margin-bottom: 4px;
}
.q-markdown--table {
  width: fit-content;
  margin-bottom: 16px;
  border-collapse: collapse;
  max-width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #9e9e9e;
}
.q-markdown--line-numbers-wrapper {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  margin: 0 0 1em 0;
  background: #eceff1;
  color: #212121;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  border-radius: 4px;
}
.q-markdown--line-numbers {
  padding: 5px;
  text-align: right;
}
.q-markdown--line-number {
  color: #9e9e9e;
  margin: 0;
  position: relative;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}
.q-markdown--code-wrapper {
  width: 100%;
  min-width: 0;
}
.q-markdown--code, .q-markdown--code__inner {
  margin: 0;
  position: relative;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}
.q-markdown--code {
  overflow: visible;
  padding: 0;
}
.q-markdown--code__inner {
  max-height: inherit;
  height: inherit;
  display: block;
  overflow: auto;
}
.q-markdown--table {
  border-color: #e0e0e0;
  background: #fafafa;
}
.q-markdown--table thead {
  background: #e0e0e0;
}
.q-markdown--table thead tr th {
  padding: 8px;
  border-width: 1px;
  border-style: solid;
  background: #f5f5f5;
}
.q-markdown--table tbody {
  background: #fafafa;
}
.q-markdown--table tbody td, .q-markdown--table tbody th {
  padding: 8px;
  border-width: 1px;
  border-style: solid;
}
.q-markdown--table tbody tr:nth-child(odd) {
  background: #e0e0e0;
}

blockquote.q-markdown--link {
  background: transparent;
}
blockquote.q-markdown--note {
  border-width: 1px 8px 1px 8px;
  border-radius: 8px;
  border-style: solid;
  border-color: #9e9e9e #009688;
}

.q-markdown__copy {
  position: absolute;
  top: 15px;
  right: 15px;
}

.body--dark .q-markdown {
  color: #f5f5f5;
}
.body--dark .q-markdown code {
  background: #424242;
  color: #f5f5f5;
}
.body--dark .q-markdown blockquote.q-markdown--note {
  border-color: #9e9e9e #9e9e9e;
  background: #1d1d1d;
  color: #f5f5f5;
}
.body--dark .q-markdown pre, .body--dark .q-markdown pre code {
  background: #1d1d1d;
}
.body--dark .q-markdown .q-markdown--line-numbers-wrapper {
  background: #1d1d1d;
  color: #f5f5f5;
}
.body--dark .q-markdown .q-markdown--token {
  background: #9e9e9e;
  color: #212121;
  border: #e0e0e0 solid 1px;
}
.body--dark .q-markdown .q-markdown--code {
  background: #1d1d1d;
  color: #212121;
}
.body--dark .q-markdown .q-markdown--note {
  background: #212121;
  color: white;
  border-top: 1px solid #424242;
  border-bottom: 1px solid #424242;
}
.body--dark .q-markdown .q-markdown--note-- {
  border-left: 10px solid #9e9e9e;
}
.body--dark .q-markdown .q-markdown--note--info {
  border-left: 10px solid #01579b;
}
.body--dark .q-markdown .q-markdown--note--tip {
  border-left: 10px solid #33691e;
}
.body--dark .q-markdown .q-markdown--note--warning {
  border-left: 10px solid #e65100;
}
.body--dark .q-markdown .q-markdown--note--danger {
  border-left: 10px solid #b71c1c;
}
.body--dark .q-markdown .q-markdown--table thead tr th,
.body--dark .q-markdown .q-markdown--table tbody {
  background-color: #1d1d1d;
}
.body--dark .q-markdown .q-markdown--table tbody tr:nth-child(2n+1) {
  background-color: #424242;
}