// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$h1:        (size: 6rem,     line-height: 6rem,     letter-spacing: -.01562em, weight: 300) !default;
$h2:        (size: 3.75rem,  line-height: 3.75rem,  letter-spacing: -.00833em, weight: 300) !default;
$h3:        (size: 3rem,     line-height: 3.125rem, letter-spacing: normal,    weight: 400) !default;
$h4:        (size: 2.125rem, line-height: 2.5rem,   letter-spacing: .00735em,  weight: 400) !default;
$h5:        (size: 1.5rem,   line-height: 2rem,     letter-spacing: normal,    weight: 400) !default;
$h6:        (size: 1.25rem,  line-height: 2rem,     letter-spacing: .0125em,   weight: 500) !default;
$subtitle1: (size: 1rem,     line-height: 1.75rem,  letter-spacing: .00937em,  weight: 400) !default;
$subtitle2: (size: .875rem,  line-height: 1.375rem, letter-spacing: .00714em,  weight: 500) !default;
$body1:     (size: 1rem,     line-height: 1.5rem,   letter-spacing: .03125em,  weight: 400) !default;
$body2:     (size: .875rem,  line-height: 1.25rem,  letter-spacing: .01786em,  weight: 400) !default;
$overline:  (size: .75rem,   line-height: 2rem,     letter-spacing: .16667em,  weight: 500) !default;
$caption:   (size: .75rem,   line-height: 1.25rem,  letter-spacing: .03333em,  weight: 400) !default;

$primary: #1A2E44;
$secondary: #072B7F;
$accent: #9C27B0;

// $dark: #1D1D1D;

// Material/NGA/Brand #CB233E
$negative: #C10015;
$info: #31CCEC;
$warning: #F2C037;

$brand: #CB233E;


$red : #f44336 !default;
$red-1 : #FFEBEE !default;
$red-2 : #ffcdd2 !default;
$red-3 : #ef9a9a !default;
$red-4 : #e57373 !default;
$red-5 : #ef5350 !default;
$red-6 : #f44336 !default;
$red-7 : #e53935 !default;
$red-8 : #d32f2f !default;
$red-9 : #c62828 !default;
$red-10 : #b71c1c !default;
$red-11 : #ff8a80 !default;
$red-12 : #ff5252 !default;
$red-13 : #ff1744 !default;
$red-14 : #d50000 !default;
$pink : #e91e63 !default;
$pink-1 : #fce4ec !default;
$pink-2 : #f8bbd0 !default;
$pink-3 : #f48fb1 !default;
$pink-4 : #f06292 !default;
$pink-5 : #ec407a !default;
$pink-6 : #e91e63 !default;
$pink-7 : #d81b60 !default;
$pink-8 : #c2185b !default;
$pink-9 : #ad1457 !default;
$pink-10 : #880e4f !default;
$pink-11 : #ff80ab !default;
$pink-12 : #ff4081 !default;
$pink-13 : #f50057 !default;
$pink-14 : #c51162 !default;
$purple : #9c27b0 !default;
$purple-1 : #f3e5f5 !default;
$purple-2 : #e1bee7 !default;
$purple-3 : #ce93d8 !default;
$purple-4 : #ba68c8 !default;
$purple-5 : #ab47bc !default;
$purple-6 : #9c27b0 !default;
$purple-7 : #8e24aa !default;
$purple-8 : #7b1fa2 !default;
$purple-9 : #6a1b9a !default;
$purple-10 : #4a148c !default;
$purple-11 : #ea80fc !default;
$purple-12 : #e040fb !default;
$purple-13 : #d500f9 !default;
$purple-14 : #aa00ff !default;
$deep-purple : #673ab7 !default;
$deep-purple-1 : #ede7f6 !default;
$deep-purple-2 : #d1c4e9 !default;
$deep-purple-3 : #b39ddb !default;
$deep-purple-4 : #9575cd !default;
$deep-purple-5 : #7e57c2 !default;
$deep-purple-6 : #673ab7 !default;
$deep-purple-7 : #5e35b1 !default;
$deep-purple-8 : #512da8 !default;
$deep-purple-9 : #4527a0 !default;
$deep-purple-10 : #311b92 !default;
$deep-purple-11 : #b388ff !default;
$deep-purple-12 : #7c4dff !default;
$deep-purple-13 : #651fff !default;
$deep-purple-14 : #6200ea !default;
$indigo : #3f51b5 !default;
$indigo-1 : #e8eaf6 !default;
$indigo-2 : #c5cae9 !default;
$indigo-3 : #9fa8da !default;
$indigo-4 : #7986cb !default;
$indigo-5 : #5c6bc0 !default;
$indigo-6 : #3f51b5 !default;
$indigo-7 : #3949ab !default;
$indigo-8 : #303f9f !default;
$indigo-9 : #283593 !default;
$indigo-10 : #1a237e !default;
$indigo-11 : #8c9eff !default;
$indigo-12 : #536dfe !default;
$indigo-13 : #3d5afe !default;
$indigo-14 : #304ffe !default;
$blue : #2196f3 !default;
$blue-1 : #e3f2fd !default;
$blue-2 : #bbdefb !default;
$blue-3 : #90caf9 !default;
$blue-4 : #64b5f6 !default;
$blue-5 : #42a5f5 !default;
$blue-6 : #2196f3 !default;
$blue-7 : #1e88e5 !default;
$blue-8 : #1976d2 !default;
$blue-9 : #1565c0 !default;
$blue-10 : #0d47a1 !default;
$blue-11 : #82b1ff !default;
$blue-12 : #448aff !default;
$blue-13 : #2979ff !default;
$blue-14 : #2962ff !default;
$light-blue : #03a9f4 !default;
$light-blue-1 : #e1f5fe !default;
$light-blue-2 : #b3e5fc !default;
$light-blue-3 : #81d4fa !default;
$light-blue-4 : #4fc3f7 !default;
$light-blue-5 : #29b6f6 !default;
$light-blue-6 : #03a9f4 !default;
$light-blue-7 : #039be5 !default;
$light-blue-8 : #0288d1 !default;
$light-blue-9 : #0277bd !default;
$light-blue-10 : #01579b !default;
$light-blue-11 : #80d8ff !default;
$light-blue-12 : #40c4ff !default;
$light-blue-13 : #00b0ff !default;
$light-blue-14 : #0091ea !default;
$cyan : #00bcd4 !default;
$cyan-1 : #e0f7fa !default;
$cyan-2 : #b2ebf2 !default;
$cyan-3 : #80deea !default;
$cyan-4 : #4dd0e1 !default;
$cyan-5 : #26c6da !default;
$cyan-6 : #00bcd4 !default;
$cyan-7 : #00acc1 !default;
$cyan-8 : #0097a7 !default;
$cyan-9 : #00838f !default;
$cyan-10 : #006064 !default;
$cyan-11 : #84ffff !default;
$cyan-12 : #18ffff !default;
$cyan-13 : #00e5ff !default;
$cyan-14 : #00b8d4 !default;
$teal : #009688 !default;
$teal-1 : #e0f2f1 !default;
$teal-2 : #b2dfdb !default;
$teal-3 : #80cbc4 !default;
$teal-4 : #4db6ac !default;
$teal-5 : #26a69a !default;
$teal-6 : #009688 !default;
$teal-7 : #00897b !default;
$teal-8 : #00796b !default;
$teal-9 : #00695c !default;
$teal-10 : #004d40 !default;
$teal-11 : #a7ffeb !default;
$teal-12 : #64ffda !default;
$teal-13 : #1de9b6 !default;
$teal-14 : #00bfa5 !default;
$green : #4caf50 !default;
$green-1 : #e8f5e9 !default;
$green-2 : #c8e6c9 !default;
$green-3 : #a5d6a7 !default;
$green-4 : #81c784 !default;
$green-5 : #66bb6a !default;
$green-6 : #4caf50 !default;
$green-7 : #43a047 !default;
$green-8 : #388e3c !default;
$green-9 : #2e7d32 !default;
$green-10 : #1b5e20 !default;
$green-11 : #b9f6ca !default;
$green-12 : #69f0ae !default;
$green-13 : #00e676 !default;
$green-14 : #00c853 !default;
$light-green : #8bc34a !default;
$light-green-1 : #f1f8e9 !default;
$light-green-2 : #dcedc8 !default;
$light-green-3 : #c5e1a5 !default;
$light-green-4 : #aed581 !default;
$light-green-5 : #9ccc65 !default;
$light-green-6 : #8bc34a !default;
$light-green-7 : #7cb342 !default;
$light-green-8 : #689f38 !default;
$light-green-9 : #558b2f !default;
$light-green-10 : #33691e !default;
$light-green-11 : #ccff90 !default;
$light-green-12 : #b2ff59 !default;
$light-green-13 : #76ff03 !default;
$light-green-14 : #64dd17 !default;
$lime : #cddc39 !default;
$lime-1 : #f9fbe7 !default;
$lime-2 : #f0f4c3 !default;
$lime-3 : #e6ee9c !default;
$lime-4 : #dce775 !default;
$lime-5 : #d4e157 !default;
$lime-6 : #cddc39 !default;
$lime-7 : #c0ca33 !default;
$lime-8 : #afb42b !default;
$lime-9 : #9e9d24 !default;
$lime-10 : #827717 !default;
$lime-11 : #f4ff81 !default;
$lime-12 : #eeff41 !default;
$lime-13 : #c6ff00 !default;
$lime-14 : #aeea00 !default;
$yellow : #ffeb3b !default;
$yellow-1 : #fffde7 !default;
$yellow-2 : #fff9c4 !default;
$yellow-3 : #fff59d !default;
$yellow-4 : #fff176 !default;
$yellow-5 : #ffee58 !default;
$yellow-6 : #ffeb3b !default;
$yellow-7 : #fdd835 !default;
$yellow-8 : #fbc02d !default;
$yellow-9 : #f9a825 !default;
$yellow-10 : #f57f17 !default;
$yellow-11 : #ffff8d !default;
$yellow-12 : #ffff00 !default;
$yellow-13 : #ffea00 !default;
$yellow-14 : #ffd600 !default;
$amber : #ffc107 !default;
$amber-1 : #fff8e1 !default;
$amber-2 : #ffecb3 !default;
$amber-3 : #ffe082 !default;
$amber-4 : #ffd54f !default;
$amber-5 : #ffca28 !default;
$amber-6 : #ffc107 !default;
$amber-7 : #ffb300 !default;
$amber-8 : #ffa000 !default;
$amber-9 : #ff8f00 !default;
$amber-10 : #ff6f00 !default;
$amber-11 : #ffe57f !default;
$amber-12 : #ffd740 !default;
$amber-13 : #ffc400 !default;
$amber-14 : #ffab00 !default;
$orange : #ff9800 !default;
$orange-1 : #fff3e0 !default;
$orange-2 : #ffe0b2 !default;
$orange-3 : #ffcc80 !default;
$orange-4 : #ffb74d !default;
$orange-5 : #ffa726 !default;
$orange-6 : #ff9800 !default;
$orange-7 : #fb8c00 !default;
$orange-8 : #f57c00 !default;
$orange-9 : #ef6c00 !default;
$orange-10 : #e65100 !default;
$orange-11 : #ffd180 !default;
$orange-12 : #ffab40 !default;
$orange-13 : #ff9100 !default;
$orange-14 : #ff6d00 !default;
$deep-orange : #ff5722 !default;
$deep-orange-1 : #fbe9e7 !default;
$deep-orange-2 : #ffccbc !default;
$deep-orange-3 : #ffab91 !default;
$deep-orange-4 : #ff8a65 !default;
$deep-orange-5 : #ff7043 !default;
$deep-orange-6 : #ff5722 !default;
$deep-orange-7 : #f4511e !default;
$deep-orange-8 : #e64a19 !default;
$deep-orange-9 : #d84315 !default;
$deep-orange-10 : #bf360c !default;
$deep-orange-11 : #ff9e80 !default;
$deep-orange-12 : #ff6e40 !default;
$deep-orange-13 : #ff3d00 !default;
$deep-orange-14 : #dd2c00 !default;
$brown : #795548 !default;
$brown-1 : #efebe9 !default;
$brown-2 : #d7ccc8 !default;
$brown-3 : #bcaaa4 !default;
$brown-4 : #a1887f !default;
$brown-5 : #8d6e63 !default;
$brown-6 : #795548 !default;
$brown-7 : #6d4c41 !default;
$brown-8 : #5d4037 !default;
$brown-9 : #4e342e !default;
$brown-10 : #3e2723 !default;
$brown-11 : #d7ccc8 !default;
$brown-12 : #bcaaa4 !default;
$brown-13 : #8d6e63 !default;
$brown-14 : #5d4037 !default;
$grey : #9e9e9e !default;
$grey-1 : #F6F8FE !default;
$grey-2 : #D9DBE4 !default;
$grey-3 : #BDBFCA !default;
$grey-4 : #e0e0e0 !default;
$grey-5 : #878A97 !default;
$grey-6 : #9e9e9e !default;
$grey-7 : #757575 !default;
$grey-8 : #616161 !default;
$grey-9 : #424242 !default;
$grey-10 : #212121 !default;
$grey-11 : #f5f5f5 !default;
$grey-12 : #eeeeee !default;
$grey-13 : #bdbdbd !default;
$grey-14 : #616161 !default;
$blue-grey : #607d8b !default;
$blue-grey-1 : #eceff1 !default;
$blue-grey-2 : #cfd8dc !default;
$blue-grey-3 : #b0bec5 !default;
$blue-grey-4 : #90a4ae !default;
$blue-grey-5 : #78909c !default;
$blue-grey-6 : #607d8b !default;
$blue-grey-7 : #546e7a !default;
$blue-grey-8 : #455a64 !default;
$blue-grey-9 : #37474f !default;
$blue-grey-10 : #263238 !default;
$blue-grey-11 : #cfd8dc !default;
$blue-grey-12 : #b0bec5 !default;
$blue-grey-13 : #78909c !default;
$blue-grey-14 : #455a64 !default;


$darken-4: #1D1E24;
$darken-3: #2C2E36;
$darken-2: #3E414B;
$darken-1: #565965;
$darken-5: #131418;
$grey-darken-5: #131418;
$dark: $darken-4;
$q-color-primary: $darken-4!important;
$positive: $red;
$red: #CB233E;

