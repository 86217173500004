.q-pdfviewer {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.q-pdfviewer__iframe {
  border: 0;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}